import Swal, { SweetAlertIcon } from "sweetalert2";

import { getGlobalTranslation } from "../i18n";

const ToastMessage = Swal.mixin({
  toast: true,
  showConfirmButton: false,
  position: "top-end",
  showCloseButton: true,
  customClass: {
    popup: "custom-toast",
  },
  showClass: {
    popup: "",
    icon: "",
  },
  hideClass: {
    popup: "",
  },
});

export const setToastMessage = ({
  icon,
  title,
  text = "",
  showConfirmButton = false,
}: {
  icon: SweetAlertIcon;
  title: string;
  text?: undefined | string;
  showConfirmButton?: boolean;
}) => {
  ToastMessage.fire({
    icon,
    title,
    text,
    timerProgressBar: true,
    timer: 3000,
    showConfirmButton,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};

export const setToastMessageLoading = ({ message }: { message?: string } = {}) => {
  ToastMessage.fire({
    title: getGlobalTranslation({ message: message ?? "message.operationInProgress" }),
    willOpen: () => {
      Swal.showLoading();
    },
  });
};

export const closeToastMessage = () => {
  ToastMessage.close();
};

export const getUserConfirmationAsBoolean = async () => {
  const confirmationResult = await Swal.fire({
    title: getGlobalTranslation({ message: "dialog.areYouSure" }),
    text: getGlobalTranslation({ message: "dialog.actionNotReversible" }),
    focusCancel: true,
    showCancelButton: true,
    cancelButtonText: getGlobalTranslation({ message: "dialog.cancelText" }),
  });

  return confirmationResult.isConfirmed;
};
